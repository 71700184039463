import React from 'react'
import * as styles from './api-error-message.module.less'
import Icon from 'src/components/icon/icon'
import { useTranslation } from 'react-i18next'

interface Props {
  error?: string
}

const APIErrorMessage: React.FC<Props> = ({ error }) => {
  const { t } = useTranslation()
  return (
    <>
      {error && (
        <div className={styles.apiError}>
          <span>
            <Icon name="sm-warning" className={styles.warningIcon} />
          </span>
          { error.length <  250 ? error : t('generic error')}
        </div>
      )}
    </>
  )
}

export default APIErrorMessage
